import { FormattedMessage } from 'react-intl';
import { AlertBar, Link } from '~/components/granular';
import { GranularLogoAlt } from '~/components/icons';

const IS_PROD = import.meta.env.PROD;

interface BaseErrorProps {
  title: string;
  message?: string;
  stack?: string;
}

export function BaseError({ title, message, stack }: BaseErrorProps) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <GranularLogoAlt width={125} height={53} />
      <div className="mt-10">
        <AlertBar size="small" appearance="error" title={title} text={message} />
        <div className="mt-4 text-center">
          <p className="body-200 text-neutral-800 ">
            <FormattedMessage id="errors.UNKNOWN" />
          </p>
          <Link href="mailto: helpdesk@granular-energy.com">helpdesk@granular-energy.com</Link>
        </div>
      </div>

      {!IS_PROD ? (
        <div className="mt-12 rounded-lg bg-red-100 p-6">
          <pre className="text-xs">{stack}</pre>
        </div>
      ) : null}
    </div>
  );
}
