import { useAuth0 } from '@auth0/auth0-react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { routes } from '~/api/routes';
import { FetchError } from '~/errors/fetch-error';
import { userConfig } from '~/global-state/userConfig';
import { useFetch } from '~/hooks/useFetch';

import * as Sentry from '@sentry/browser';

import * as Highcharts from 'highcharts';
import type { TimeDefinition } from '~/schemas/enums';
import { type UserConfigApiResponse, userConfigSchema } from '~/schemas/organizations';

export const useUserConfig = () => {
  const { fetchData } = useFetch();
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useAuth0();

  const [_user, setUser] = useAtom(userConfig);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated || !isLoading) return;

    function setHighchartsUserConfig(timezone: string) {
      Highcharts.setOptions({
        time: {
          timezone,
        },
      });
    }

    async function getUserConfig() {
      try {
        const userConfig = await fetchData<undefined, UserConfigApiResponse>(
          routes.userConfig.getUserConfig,
          userConfigSchema,
        );

        const orgName = userConfig?.end_consumer?.name || userConfig?.supplier.name || '-';

        setUser({
          orgName,
          isEndConsumer: !!userConfig?.end_consumer,
          logoUrl: userConfig?.supplier.logo_url || undefined,
          timezone: userConfig?.supplier.default_timezone,
          countryCode: userConfig?.supplier.country_code,
          timeDefinitions: userConfig?.supplier.time_definitions?.map((td) => td.toUpperCase() as TimeDefinition) || [],
          welcomeScreenEnabled: userConfig?.end_consumer?.welcome_screen_enabled || false,
          hideProductionDevicesData: userConfig?.end_consumer?.hide_production_devices || false,
        });

        Sentry.setTag('organization_name', orgName);

        setHighchartsUserConfig(userConfig?.supplier.default_timezone);
        setIsLoading(false);
      } catch (e) {
        const appInitError = new FetchError(formatMessage({ id: 'errors.userConfig' }));
        appInitError.cause = e;

        setIsLoading(false);
        setError(appInitError);
      }
    }

    getUserConfig();
  }, [isLoading, isAuthenticated, setUser, fetchData, formatMessage]);

  if (error) {
    throw error;
  }

  return { isLoading };
};
