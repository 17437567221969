import type { LinksFunction } from '@remix-run/node';
import { Links, Meta, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { RootErrorBoundary } from '~/components/errors';
import { Loading } from '~/components/layouts/loading';
import { Root } from '~/components/layouts/root';
import { useAuthentication, useMixpanel } from '~/hooks';
import { useLocales } from '~/intl';
import { useUserConfig } from '~/query-hooks';

import mapboxGeocodeStyleSheet from '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css?url';
import agGridStylesheet from 'ag-grid-enterprise/styles/ag-grid.css?url';
import agGridQuartzStylesheet from 'ag-grid-enterprise/styles/ag-theme-quartz.css?url';
import agGridCustomStylesheet from 'app/components/granular/ag-grid/ag-grid.css?url';
import dataGridStylesheet from 'app/components/granular/data-grid/styles.css?url';
import datePickerStyles from 'app/components/granular/date-picker/DatePicker.css?url';
import dropdownStylesheet from 'app/components/granular/dropdown/styles.css?url';
import geocoderStylesheet from 'app/components/shared/geocoder/geocoder.css?url';
import mapboxStylesheet from 'mapbox-gl/dist/mapbox-gl.css?url';
import skeletonStylesheet from 'react-loading-skeleton/dist/skeleton.css?url';
import stylesheet from './styles.css?url';

const googleFontsStylesheet =
  'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Space+Grotesk:wght@500&display=swap';

const stylesheets = [
  googleFontsStylesheet,
  stylesheet,
  mapboxStylesheet,
  mapboxGeocodeStyleSheet,
  geocoderStylesheet,
  agGridStylesheet,
  agGridQuartzStylesheet,
  agGridCustomStylesheet,
  dataGridStylesheet,
  datePickerStyles,
  dropdownStylesheet,
  skeletonStylesheet,
].map((href) => ({ rel: 'stylesheet', href }));

export const links: LinksFunction = () => [{ rel: 'preconnect', href: 'https://fonts.gstatic.com' }, ...stylesheets];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="m-0">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(function App() {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuthentication();
  const { setDateLocale } = useLocales();
  const { isLoading: isUserLoading } = useUserConfig();
  useMixpanel();
  setDateLocale();

  if (isAuthLoading || isUserLoading || !isAuthenticated) {
    return <HydrateFallback />;
  }

  return <Root />;
});

export function HydrateFallback() {
  return (
    <div className="flex min-h-screen min-w-full items-center justify-center">
      <Loading />
    </div>
  );
}

export function ErrorBoundary() {
  captureRemixErrorBoundaryError(useRouteError());
  return <RootErrorBoundary />;
}
